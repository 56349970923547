import type { ReactNode } from "react";
import { Link } from "@mui/material";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

interface SocialIconProps {
  name: "facebook" | "instagram" | "youtube";
  link: string;
}

const socialIconMap: Record<SocialIconProps["name"], ReactNode> = {
  facebook: <FaFacebookF />,
  instagram: <FaInstagram />,
  youtube: <FaYoutube />,
};

const SocialIconLink = ({ name, link }: SocialIconProps) => {
  const Icon = socialIconMap[name];
  return (
    <Link
      sx={{
        color: "#fff",
        border: "1px solid #fff",
        borderRadius: "6px",
        px: 0.8,
        py: 0.8,
        display: "flex",
      }}
      aria-label='name'
      href={link}
      target='_blank'
      rel='noreferrer'
    >
      {Icon}
    </Link>
  );
};

export default SocialIconLink;
