import hondaInnerImage from "../../../assets/images/disinfect-service-interior.png"
import boxIcon1 from "../../../assets/images/box-icon1.png"
import boxIcon2 from "../../../assets/images/box-icon2.png"
import boxIcon3 from "../../../assets/images/box-icon3.png"
import { Grid, Stack, Typography } from "@mui/material"
import { spacing, appColors } from "../../../theme"
import {
    DisinfectContainer, HondaButton,
    IconBox, ResponsiveTypography
} from "./DisinfectServiceComponents"

const DisinfectationServiceFragment = () => {
    return (
        <DisinfectContainer container paddingX={{ xs: 5, md: spacing.paddingX }}
            paddingY={spacing.paddingY} alignItems='center'>
            <Grid item xs={12} sm={12} md={6}>
                <Stack justifyContent={{ xs: 'center' }} >
                    <ResponsiveTypography fontWeight={600} fontSize={19} lineHeight={3}>Because we care for your safety</ResponsiveTypography>
                    <ResponsiveTypography fontSize={{ xs: 45, md: 60 }} lineHeight={1.1}>Honda Cabin <br /> Disinfectation Service</ResponsiveTypography>
                    <ResponsiveTypography lineHeight={3} fontSize={16} marginBottom={3}>Reduce the risk of acquiring or spreading virus from your vehicle</ResponsiveTypography>
                    <HondaButton display={{ xs: 'none', md: 'flex' }}>
                        More Details
                    </HondaButton>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <img src={hondaInnerImage} height={270} style={{ width: '100%', borderRadius: 15 }} />
                <HondaButton display={{ xs: 'flex', md: 'none' }} marginy={3}>
                    More Details
                </HondaButton>
                <Typography lineHeight={3} color={appColors.colorText}>
                    Benefits Includes:
                </Typography>

                <Grid container direction="row">
                    <Grid item xs={4}>
                        <IconBox label="Organic & Non-Toxic" src={boxIcon2} textWidth={{ xs: '8ch', md: '10ch' }} />
                    </Grid>
                    <Grid item xs={4}>
                        <IconBox label='No Harmful Chemical' src={boxIcon3} textWidth={{ xs: '10ch', md: '14ch' }} />
                    </Grid>
                    <Grid item xs={4}>
                        <IconBox label='Non-Hazardous & Environment Friendly' src={boxIcon1} textWidth={{ xs: '20ch', md: '30ch' }} />
                    </Grid>
                </Grid>
            </Grid>
        </DisinfectContainer>
    )
}

export default DisinfectationServiceFragment;
