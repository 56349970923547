import { Stack, Typography } from "@mui/material";
import SocialIconLink from "../lib/SocialIconLink";

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <Stack
      alignItems='center'
      justifyContent='space-between'
      gap={3}
      bgcolor='black'
      color='#eee'
      sx={{
        px: { md: 12, xs: 2 },
        py: { xs: 4, md: 2 },
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Typography fontSize={18} textAlign='center'>
        Copyright {year} Honda Cars Batangas | All Rights Reserved
      </Typography>
      <Stack direction='row' alignItems='center' gap={2}>
        <SocialIconLink name='facebook' link='https://www.facebook.com/hondacarsbatangas/' />
        <SocialIconLink
          name='instagram'
          link='https://www.instagram.com/honda_cars_batangas/?hl=en'
        />
        <SocialIconLink
          name='youtube'
          link='https://www.youtube.com/channel/UCKkNfeZJBMjeATsZEOP8DJw'
        />
      </Stack>
    </Stack>
  );
};

export default Footer;
