import { ChevronRight } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import RedLink from "../../components/lib/RedLink";
import type { Career } from "./mock";

interface CareerItemProps {
  career: Career;
}

const CareerItem = ({ career }: CareerItemProps) => {
  return (
    <Stack
      py={2}
      px={3}
      bgcolor='white'
      borderRadius={1}
      direction='row'
      justifyContent='space-between'
    >
      <Typography fontSize={18} fontWeight={500} maxWidth='50%'>
        {career.position}
      </Typography>
      <RedLink to={`/careers/${career.id}`}>
        More Details
        <ChevronRight fontSize='small' />
      </RedLink>
    </Stack>
  );
};

export default CareerItem;
