import { Container, Stack } from "@mui/material";
import { Switch, Route } from "react-router-dom";
import { appColors } from "../../theme";
import CareerDetails from "./CareerDetails";
import CareerList from "./CareerList";

const CareersPage = () => {
  return (
    <Stack
      component='section'
      bgcolor={appColors.softGray}
      sx={{ px: { xs: 1, lg: 6 }, py: { xs: 2, lg: 5 } }}
      minHeight='85vh'
    >
      <Container maxWidth='xl'>
        <Switch>
          <Route path='/careers/:id'>
            <CareerDetails />
          </Route>
          <Route path='/careers'>
            <CareerList />
          </Route>
        </Switch>
      </Container>
    </Stack>
  );
};

export default CareersPage;
