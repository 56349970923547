import { Stack, Drawer } from "@mui/material";
import { styled } from "@mui/system";

import hondaLogo from "../../assets/images/honda-logo.png";
import { appColors } from "../../theme";
import MenuToggler from "../lib/MenuToggler";

import useIsTabletOrMobile from "../../hooks/useIsTabletOrMobile";
import { navItems } from "../../constants";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import MobileSearchBar from "../lib/MobileSearchBar";
import useMeasure from "../../hooks/useMeasure";

const MobileLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
  fontSize: "1.5rem",
  padding: "10px 0",
  borderBottom: `1px solid ${appColors.lightestGray}`,
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const VirtualShowroomLink = styled(Link)(({ theme }) => ({
  color: "inherit",
  textDecoration: "none",
  position: "relative",
  fontSize: "1.5rem",
  padding: "8px 12px",
  border: `1px solid ${appColors.lightGray}`,
  borderRadius: "8px",
  transition: "0.3s",
  textAlign: "center",
  marginTop: 10,

  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderColor: theme.palette.primary.main,
  },
}));

interface MobileNavigationProps {
  open: boolean;
  onClose: () => void;
}

const MobileNavigation = ({ onClose, open }: MobileNavigationProps) => {
  const isTabletOrMobile = useIsTabletOrMobile();

  const addressBarBounds = useMeasure("#addressBar");

  if (!isTabletOrMobile) return null;

  return (
    <Drawer
      hideBackdrop
      open={open}
      anchor='right'
      sx={{
        width: "100vw",
        flexShrink: 0,
        overflow: "auto",
        [`& .MuiDrawer-paper`]: {
          width: "100vw",
          boxSizing: "border-box",
          bgcolor: "transparent",
        },
      }}
    >
      <Stack height={addressBarBounds ? addressBarBounds.height : 40} flexShrink={0} />

      <Stack overflow='auto' py={2} px={3} bgcolor='white' height='100%'>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          position='relative'
        >
          <Stack>
            <img src={hondaLogo} alt='Honda Batangas' height={42} style={{ marginTop: "10px" }} />
          </Stack>
          <MenuToggler variant='close' onToggle={onClose} />
        </Stack>
        <MobileSearchBar />
        <Stack component='ul' p={0}>
          {navItems.map((nav) => (
            <Stack component='li' key={nav.label} onClick={onClose}>
              <MobileLink to={nav.path}>
                {nav.label} <ChevronRight />
              </MobileLink>
            </Stack>
          ))}
        </Stack>
        <VirtualShowroomLink to='/virtual-showroom'>Virtual Showroom</VirtualShowroomLink>
      </Stack>
    </Drawer>
  );
};

export default MobileNavigation;
