import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";

const SearchContainer = styled("div")({
  display: "flex",
  marginTop: "1.2rem",
  position: "relative",

  "& > svg": {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    color: "#ccc",
  },
});

const SearchInput = styled("input")(({ theme }) => ({
  padding: "10px 16px",
  width: "100%",
  backgroundColor: "#F8F8F8",
  borderRadius: 4,
  fontFamily: "Barlow Condensed",
  fontSize: "1.4rem",
  border: "2px solid transparent",
  outlineWidth: 0,

  "&:focus": {
    borderColor: theme.palette.primary.main,
  },
}));

const MobileSearchBar = () => {
  return (
    <SearchContainer>
      <SearchInput placeholder='Search' aria-label='search' />
      <SearchIcon />
    </SearchContainer>
  );
};

export default MobileSearchBar;
