import { useEffect, useState } from "react";

export default function useMeasure(query?: string) {
  const [bounds, setBounds] = useState<DOMRect>();

  useEffect(() => {
    const resizeCallback = () => {
      const element = query ? document.querySelector(query) : document.body;

      if (element !== null) {
        const boundingRect = element.getBoundingClientRect();

        setBounds(boundingRect);
      }
    };

    window.addEventListener("resize", resizeCallback);

    resizeCallback();

    return () => window.removeEventListener("resize", resizeCallback);
  }, [query]);

  return bounds;
}
