import Button, { ButtonProps } from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const SubmitButton = (props: ButtonProps) => {
  return (
    <Button
      disableElevation
      disableRipple
      sx={{ fontSize: 17, borderRadius: "10px", px: 4, width: { xs: "100%", md: "170px" } }}
      variant='contained'
      endIcon={<ArrowForwardIcon fontSize='small' />}
      {...props}
    >
      Submit
    </Button>
  );
};

export default SubmitButton;
