export const navItems = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Model",
    path: "/model",
  },
  {
    label: "Parts & Service",
    path: "/parts-and-service",
  },
  {
    label: "Promos",
    path: "/promos",
  },
  {
    label: "Careers",
    path: "/careers",
  },
  {
    label: "News & Events",
    path: "/news-and-events",
  },
  {
    label: "HCBT Elite Card",
    path: "/hcbt",
  },
];
