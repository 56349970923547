export type Career = {
  id: string;
  position: string;
  qualifications: string[];
};

const careerData: Career[] = [
  {
    id: "1",
    position: "Sales Executive",
    qualifications: [
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
    ],
  },
  {
    id: "2",
    position: "Pre-delivery Inspector / TNT Installer",
    qualifications: [
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
    ],
  },
  {
    id: "3",
    position: "Tinsmith",
    qualifications: [
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
      "Lorem ipsum dolor test text just a sample",
    ],
  },
];

export default careerData;
