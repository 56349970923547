import { Stack, Typography } from "@mui/material";
import PlaceIcon from "@mui/icons-material/PlaceOutlined";
import PhoneIcon from "@mui/icons-material/LocalPhoneOutlined";

const AddressBar = () => {
  return (
    <Stack
      id='addressBar'
      direction='row'
      alignItems='center'
      gap={3}
      //   position='fixed'
      //   top={0}
      //   left={0}
      //   right={0}
      bgcolor='black'
      color='#eee'
      py={1}
      sx={{ px: { md: 12, xs: 2 }, justifyContent: { md: "end", xs: "space-between" } }}
    >
      <Stack
        direction='row'
        gap={1}
        sx={{ alignItems: "center", width: { xs: "60%", md: "auto" } }}
      >
        <PlaceIcon fontSize='small' />
        <Typography fontSize={15}>
          Honda Cars Batangas - Diversion Road, Bolbok, Batangas City
        </Typography>
      </Stack>
      <Stack direction='row' gap={1} sx={{ alignItems: { xs: "start", md: "center" } }}>
        <PhoneIcon fontSize='small' />
        <Typography fontSize={15}> (043) 702-9376</Typography>
      </Stack>
    </Stack>
  );
};

export default AddressBar;
