import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { appColors } from "../../theme";

interface MenuTogglerProps {
  variant?: "close" | "open";
  onToggle: () => void;
}

const MenuToggler = ({ onToggle, variant }: MenuTogglerProps) => {
  return (
    <IconButton
      aria-label='toggle menu'
      size='small'
      disableRipple
      onClick={onToggle}
      sx={{
        color: appColors.dark,
        borderRadius: 1,
        border: 1,
        ml: "auto",
        borderColor: appColors.lightGray,
        display: { xs: "flex", md: "none" },
      }}
    >
      {variant === "close" ? <CloseIcon /> : <MenuIcon />}
    </IconButton>
  );
};

export default MenuToggler;
