import { Link, NavLink } from "react-router-dom";
import { Container, Stack, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";

import MenuToggler from "../lib/MenuToggler";
import MobileNavigation from "./MobileNavigation";
import hondaLogo from "../../assets/images/honda-logo.png";
import { appColors } from "../../theme";
import { useState } from "react";
import { navItems } from "../../constants";

const AppLink = styled(NavLink)(({ theme }) => ({
  color: "inherit",
  textDecoration: "none",
  position: "relative",
  fontSize: "1.1rem",

  "&::after": {
    position: "absolute",
    bottom: -8,
    right: "50%",
    content: '""',
    width: 35,
    borderRadius: 2,
    height: 3,
    transition: "transform 0.3s",
    transform: "translateX(50%) scaleX(0)",
  },

  "&.active::after": {
    backgroundColor: theme.palette.primary.main,
    transform: "translateX(50%) scaleX(1)",
  },
}));

const VirtualShowroomLink = styled(Link)(({ theme }) => ({
  color: "inherit",
  textDecoration: "none",
  position: "relative",
  fontSize: "1.1rem",
  padding: "6px 12px",
  border: "1px solid #222",
  borderRadius: "8px",
  transition: "0.3s",

  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderColor: theme.palette.primary.main,
  },
}));

const NavigationBar = () => {
  const [mobileNavShown, setMobileNavShown] = useState(false);

  const toggleMobileNav = () => setMobileNavShown((val) => !val);

  return (
    <Stack
      position='relative'
      component='nav'
      sx={{ px: { xs: 1, lg: 6 }, pb: { xs: 2, md: 0 } }}
      pt={1}
    >
      <Container maxWidth='xl'>
        <Stack
          direction='row'
          alignItems='center'
          pb={1}
          sx={{
            borderBottom: { xs: `2px solid ${appColors.lightestGray}`, md: "none" },
          }}
        >
          <Link to='/' aria-label='home'>
            <img src={hondaLogo} alt='Honda Batangas' height={42} style={{ marginTop: "14px" }} />
          </Link>
          <Stack
            component='ul'
            direction='row'
            alignItems='center'
            gap={3}
            ml='auto'
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {navItems.map((nav) => (
              <Stack component='li' key={nav.label}>
                <AppLink to={nav.path} exact={nav.path === "/"}>
                  {nav.label}
                </AppLink>
              </Stack>
            ))}
            <Stack component='li' mr={2}>
              <VirtualShowroomLink to='/virtual-showroom'>Virtual Showroom</VirtualShowroomLink>
            </Stack>
          </Stack>
          <IconButton
            aria-label='search'
            disableRipple
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <SearchIcon />
          </IconButton>
          <MenuToggler onToggle={toggleMobileNav} />
        </Stack>
      </Container>
      {/* Mobile nav here */}
      <MobileNavigation open={mobileNavShown} onClose={toggleMobileNav} />
    </Stack>
  );
};

export default NavigationBar;
