// @ts-ignore
import MessengerCustomerChat from 'react-messenger-customer-chat';
const MessengerChat = () => {
    const facebookPageId = "101504285954148" // https://www.facebook.com/help/1503421039731588
    // https://developers.facebook.com/ <-- navigate to this link and login using the facebook page account

    // to get the facebook app id, you will need to create an app under the facebook page account
    const facebookAppId = "366836012206204"

    // you will also need to add the URL to the white-listed domains inside the page account
    // navigate to facebook.com > login the page account > go to settings > advance message
    // under "White-listed domains" add our URL for product,uat,staging and dev
    return (
        <MessengerCustomerChat
            pageId={facebookPageId}
            appId={facebookAppId}
            themeColor='#BB3632'
            language="sv_US"
        />
    )
};

export default MessengerChat;