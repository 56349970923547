import React from "react";
import DisinfectationServiceSection from "./fragments/DisinfectationService";

export default () => {
    return (
        <React.Fragment>
            <DisinfectationServiceSection />
        </React.Fragment>
    )
}
