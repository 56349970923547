import { Stack, TextField, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import SubmitButton from "../../components/lib/SubmitButton";

const inputStyles: SxProps = {
  bgcolor: "white",
  border: "none",
  "& .MuiOutlinedInput-root > fieldset": {
    borderColor: "white",
  },
  "&:focus": { borderColor: "#ccc" },
};

const InquiryForm = () => {
  return (
    <Stack component='form' mt={1.4} gap={3} mb={8}>
      <Typography component='h3' fontWeight='600' fontSize={20}>
        Inquire now
      </Typography>
      <Stack gap={2}>
        <Stack sx={{ flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
          <TextField
            fullWidth
            aria-label='firstName'
            size='small'
            sx={inputStyles}
            name='firstName'
            placeholder='First Name'
          />
          <TextField
            fullWidth
            aria-label='lastName'
            size='small'
            sx={inputStyles}
            name='lastName'
            placeholder='Last Name'
          />
        </Stack>
        <TextField
          aria-label='email'
          size='small'
          sx={inputStyles}
          type='email'
          name='email'
          placeholder='Email'
        />
        <TextField
          aria-label='phoneNumber'
          size='small'
          sx={inputStyles}
          name='phoneNumber'
          placeholder='Phone number'
        />
        <TextField
          aria-label='message'
          size='small'
          multiline
          rows={4}
          sx={inputStyles}
          name='message'
          placeholder='Comment or Message'
        />
        <SubmitButton />
      </Stack>
    </Stack>
  );
};

export default InquiryForm;
