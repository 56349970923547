import { Container, Stack } from "@mui/material";
import { appColors } from "../../theme";
import PartsAndService from "./PartsAndService";

const PartsAndServicePage = () => {
  return (
    <Stack
        component='section'
        bgcolor={appColors.softGray}
        sx={{ px: { xs: 1, lg: 6 }, py: { xs: 2, lg: 5 } }}
        minHeight='85vh'
    >
      <Container maxWidth='xl'>
        <PartsAndService />
      </Container>
    </Stack>
  )
}

export default PartsAndServicePage;