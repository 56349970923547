import { Divider, Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useParams } from "react-router-dom";
import careerData from "./mock";
import InquiryForm from "./InquiryForm";
import RedLink from "../../components/lib/RedLink";
import { ChevronLeft } from "@mui/icons-material";

const CareerDetails = () => {
  const { id } = useParams<{ id: string }>();

  const career = careerData.find((c) => c.id === id);

  if (!career) return null;

  return (
    <Stack sx={{ flexDirection: { xs: "column", md: "row" }, gap: { xs: 2, md: 10 } }}>
      <Stack flex={1} gap={2}>
        <Typography component='h2' fontWeight='600' fontSize={38}>
          {career.position}
        </Typography>
        <Divider />
        <Stack>
          <Typography component='h3' fontWeight='600' fontSize={20}>
            Qualifications
          </Typography>
          <Stack component='ul' gap={2} pl={0}>
            {career.qualifications.map((item, index) => (
              <Stack component='li' direction='row' alignItems='center' gap={3} key={index}>
                <CircleIcon sx={{ fontSize: "10px" }} />
                <Typography fontSize={18}>{item}</Typography>
              </Stack>
            ))}
          </Stack>
          <RedLink to='/careers'>
            <ChevronLeft fontSize='small' />
            Job Openings
          </RedLink>
        </Stack>
      </Stack>
      <Stack flex={1}>
        <InquiryForm />
      </Stack>
    </Stack>
  );
};

export default CareerDetails;
