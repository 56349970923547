import { BrowserRouter } from "react-router-dom";

import AddressBar from "./components/layout/AddressBar";
import Footer from "./components/layout/Footer";
import NavigationBar from "./components/layout/NavigationBar";
import ContentPage from "./modules";
import MessengerChat from "./components/common/Messenger";

const App = () => {
  return (
    <BrowserRouter>
      <AddressBar />
      <NavigationBar />
      <ContentPage />
      <Footer />
      <MessengerChat />
    </BrowserRouter>
  );
};

export default App;