import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ReactNode } from "react";

import "@fontsource/barlow-condensed";

export const appColors = {
  blackHeader: "#111111",
  colorText: "#ccc",
  lightGray: "#ccc",
  lightestGray: "#f3f3f3",
  softGray: "#F8F8F9",
  dark: "#222",
};

export const spacing = {
  paddingX: 12,
  paddingY: 7,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#E11428",
    },
    secondary: {
      main: "#9a9a9a",
    },
    text: {
      primary: "#222222",
      secondary: "#9a9a9a",
    },
  },
  typography: {
    fontFamily: "Barlow Condensed",
  },
});

export default function AppThemeProvider({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
