import { Stack, Typography } from "@mui/material";
import CareerItem from "./CareerItem";
import careerData from "./mock";

const CareerList = () => {
  return (
    <>
      <Typography component='h2' fontWeight='700' fontSize={42}>
        Career
      </Typography>
      <Typography fontSize={18}>See something that interests you? Apply here</Typography>
      <Stack gap={1.7} mt={2}>
        {careerData.map((career) => (
          <CareerItem key={career.id} career={career} />
        ))}
      </Stack>
    </>
  );
};

export default CareerList;
