import { Stack, Typography } from "@mui/material";
import InquiryForm from "./InquiryForm";

const PartsAndService = () => {
  return (
    <>
      <Typography component='h2' fontWeight='700' fontSize={42}>
        Parts & Service
      </Typography>
      <Stack>
        {/* Parts and Services Items here */}
      </Stack>
      <Stack sx={{ flexDirection: { xs: "column", md: "row" }, gap: { xs: 2, md: 12 } }}>
        <Stack flex={1} gap={3}>
            <Stack mt={1.4} gap={3} mb={7} sx={{ textAlign: { xs: "center", sm: "left",  md: "left" } }}>
                <Typography component='h3' fontWeight='600' fontSize={20}>
                    Inquire now for parts & services
                </Typography>
                <Stack gap={3}>
                  <Typography lineHeight={1.8}>
                    Equipped with highly skilled technicians and latest diagnostic equipment,
                    Honda Cars Batangas will take good care of your car needs.
                  </Typography>
                  <Typography lineHeight={1.8}>
                    From engine overhauling, transmission repair, brakes, under chassis,
                    electrical and all mechanical repairs, we can surely hlep fix your vehicle
                    guaranteed by superior quality parts for your safety.
                 </Typography>
                </Stack>
            </Stack>
        </Stack>
        <Stack flex={1}>
            <InquiryForm />
        </Stack>
      </Stack>
    </>
  );
};

export default PartsAndService;