import { Route, Switch } from "react-router-dom";
import HomePage from "./home";
import PartsAndServicePage from "./parts-and-service";
import CareersPage from "./career";

const ContentPage = () => {
  return (
    <main>
      <Switch>
        <Route exact path='/'>
          <HomePage />
        </Route>
        <Route path='/parts-and-service'>
          <PartsAndServicePage />
        </Route>
        <Route path='/careers'>
          <CareersPage />
        </Route>
      </Switch>
    </main>
  );
};

export default ContentPage;
